import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from './card/card.component';

const CARD_COMPONENTS = [CardComponent, CardHeaderComponent, CardFooterComponent, CardBodyComponent];

@NgModule({
  declarations: [CardComponent, CardHeaderComponent, CardFooterComponent, CardBodyComponent],
  imports: [CommonModule],
  exports: [CARD_COMPONENTS]
})
export class CardModule {}

import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { InteractiveSchematicService } from '../interactive-schematic.service';
import { SelectListViewModel, TyreSizeViewModel } from '../../../../../shared/tams-api';

@Component({
  selector: 'app-wheel-input-dialog',
  templateUrl: './wheel-input-dialog.component.html',
  styleUrls: ['./wheel-input-dialog.component.scss']
})
export class WheelInputDialogComponent implements OnInit, OnDestroy {
  removalReasons: SelectListViewModel[] = [];
  nonTyreServices: SelectListViewModel[] = [];
  sizes: TyreSizeViewModel[] = [];
  fitments: any[] = [];
  axlePos: string = '';
  speedRatings: string[] = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'J',
    'K',
    'L',
    'M',
    'N',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'H',
    'V',
    'Z',
    'W',
    'Y',
    '(Y)'
  ];
  showFitments = true; // Manage visibility of fitments
  selectedFitment: any = null;

  @Output() confirmClicked = new EventEmitter<any>();

  jobItemForm = new FormGroup({
    tyreSize: new FormControl('', [Validators.required]),
    removalReason: new FormControl(''),
    nonTyreService: new FormControl(''),
    speedRating: new FormControl('', Validators.required),
    loadRating: new FormControl('', [Validators.required]),
    ratingsUnknown: new FormControl(false),
    selectedFitment: new FormControl('')
  });

  filteredSizes!: Observable<any[]>;
  showManualInput: boolean = false;
  private destroy$ = new Subject<any>();

  constructor(
    public dialogRef: MatDialogRef<WheelInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private schematicService: InteractiveSchematicService
  ) {
    this.axlePos = data.axleId + data.wheelNumber;
    this.getSizesForVehicle(data.customerFleetListId);
    if (this.data.jobType === 'Tyres') {
      this.getDefectReasons();
    } else {
      this.getNonTyreServices();
    }
  }

  ngOnInit(): void {
    this.filteredSizes = this.jobItemForm.get('tyreSize')!.valueChanges.pipe(
      startWith(''),
      map((value) => (value ? this._filterSizes(value) : []))
    );

    // Adjust form control validators based on jobType
    this.adjustValidators(this.data.jobType);

    // If there are fitments in the vehicle data from DriveRight then we can suggest them
    if (this.data.vehicle.tyreFitments != null && this.data.vehicle.tyreFitments.length > 0) {
      const uniqueFitments = new Set();
      this.fitments = [];
      // Split front and rear fitments out
      this.data.vehicle.tyreFitments.forEach((fitment: any) => {
        const frontKey = `front-${fitment.tyreSize}`;
        const rearKey = `rear-${fitment.tyreSizeR}`;

        // Front fitment
        if (!uniqueFitments.has(frontKey) && fitment.tyreSize && fitment.tyreSize.trim() !== '') {
          uniqueFitments.add(frontKey);
          this.fitments.push(this.createFitmentObject(fitment, 'front'));
        }

        // Rear fitment
        if (!uniqueFitments.has(rearKey) && fitment.tyreSizeR && fitment.tyreSizeR.trim() !== '') {
          uniqueFitments.add(rearKey);
          this.fitments.push(this.createFitmentObject(fitment, 'rear'));
        }
      });
    } else {
      this.showManualInput = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  createFitmentObject(fitment: any, type: any) {
    return {
      fitmentType: type,
      fitmentId: fitment.fitmentId,
      vehicleId: fitment.vehicleId,
      lastUpdatedDate: fitment.lastUpdatedDate,
      chassisId: fitment.chassisId,
      modelName: fitment.modelName,
      modelId: fitment.modelId,
      kw: fitment.kw,
      ukYear: fitment.ukYear,
      euroYear: fitment.euroYear,
      tyreSize: type === 'front' ? fitment.tyreSize : fitment.tyreSizeR,
      loadIndex: type === 'front' ? fitment.loadIndex : fitment.loadIndexR,
      speedIndex: type === 'front' ? fitment.speedIndex : fitment.speedIndexR,
      tyrePressure: type === 'front' ? fitment.tyrePressure : fitment.tyrePressureR,
      rimSize: type === 'front' ? fitment.rimSize : fitment.rimSizeR,
      rimOffset: type === 'front' ? fitment.rimOffset : fitment.offsetR,
      modelLadenTp: type === 'front' ? fitment.modelLadenTpF : fitment.modelLadenTpR,
      runflat: type === 'front' ? fitment.runflatF : fitment.runflatR,
      extraload: type === 'front' ? fitment.extraloadF : fitment.extraloadR,
      oeDescription: fitment.oeDescription,
      changeDate: fitment.changeDate,
      tpPsi: type === 'front' ? fitment.tpFPsi : fitment.tpRPsi,
      ltpPsi: type === 'front' ? fitment.ltpFPsi : fitment.ltpRPsi,
      pcd: fitment.pcd,
      centreBore: fitment.centreBore,
      nutBoltThreadType: fitment.nutBoltThreadType,
      nutBoltHex: fitment.nutBoltHex,
      boltLength: fitment.boltLength,
      nutBoltTorque: fitment.nutBoltTorque,
      sortOrder: fitment.sortOrder
    };
  }

  getSizesForVehicle(customerFleetListId: number) {
    this.schematicService.getProductSizes(customerFleetListId).subscribe((data) => {
      this.sizes = data;
      const tyreSizeControl = this.jobItemForm.get('tyreSize');
      if (tyreSizeControl) {
        tyreSizeControl.setValidators([Validators.required]);
        tyreSizeControl.updateValueAndValidity();
      }
    });
  }

  getDefectReasons() {
    this.schematicService
      .getDefectReasons()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.removalReasons = data;
      });
  }

  getNonTyreServices() {
    this.schematicService
      .getNonTyreServices()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.nonTyreServices = data;
      });
  }

  private _filterSizes(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.sizes.filter((size) => size.productSizeShort?.toLowerCase().includes(filterValue));
  }

  confirm() {
    if (!this.showManualInput && !this.jobItemForm.get('selectedFitment')?.value) {
      this.jobItemForm.controls.selectedFitment.setErrors({ required: true });
      return;
    }

    // If the ratings are unknown then set the vals
    if (this.jobItemForm.controls.ratingsUnknown.value) {
      this.jobItemForm.patchValue({
        loadRating: 'Unknown',
        speedRating: 'Unknown'
      });
    }
    if (this.jobItemForm.valid) {
      const formData = {
        axlePos: this.axlePos,
        ...this.jobItemForm.value,
        positionCode: this.data.positionCode,
        removalReason: this.jobItemForm.value.removalReason,
        nonTyreService: this.jobItemForm.value.nonTyreService,
        axleType: this.data.axleType
      };
      this.dialogRef.close(formData);
    }
  }

  selectFitment(fitment: any) {
    this.jobItemForm.controls.selectedFitment.setValue(fitment);
    this.selectedFitment = fitment;

    // It's possible DriveRight will return an empty string for indexes, so handle those
    const sIndex = fitment.speedIndex || 'Unknown';
    const lIndex = fitment.loadIndex || 'Unknown';

    // Populate the required controls with the selected fitment data
    this.jobItemForm.patchValue({
      tyreSize: fitment.tyreSize,
      loadRating: lIndex,
      speedRating: sIndex
    });

    this.showFitments = false; // Hide other fitments upon selection
  }

  private adjustValidators(jobType: string) {
    const removalReasonControl = this.jobItemForm.get('removalReason');
    const nonTyreServiceIdControl = this.jobItemForm.get('nonTyreServiceId');

    if (jobType === 'Tyres') {
      removalReasonControl?.setValidators([Validators.required]);
      nonTyreServiceIdControl?.clearValidators();
    } else if (jobType === 'Services') {
      removalReasonControl?.clearValidators();
      nonTyreServiceIdControl?.setValidators([Validators.required]);
    }

    removalReasonControl?.updateValueAndValidity();
    nonTyreServiceIdControl?.updateValueAndValidity();
  }
}

<div [ngSwitch]="type">
  <div *ngSwitchCase="'error'">
    <div role="alert" class="rounded border-l-4 border-red-900 bg-red-500 dark:bg-red-700 p-4">
      <div class="flex items-center gap-1 text-grey-500">
        <fa-icon [icon]="faExclamation" class="text-white"></fa-icon>
        <strong class="block font-extrabold text-lg text-white">{{ alertTitle }}</strong>
      </div>

      <p class="mt-2 text-sm text-white">
        {{ alertMessage }}
      </p>
    </div>
  </div>

  <div *ngSwitchCase="'info'">
    <div role="alert" class="rounded border-l-4 border-blue-900 bg-blue-500 dark:bg-blue-700 p-4">
      <div class="flex items-center gap-1 text-white">
        <fa-icon [icon]="faInfo"></fa-icon>
        <strong class="block font-extrabold text-lg">{{ alertTitle }}</strong>
      </div>

      <p class="mt-2 text-sm text-white">
        {{ alertMessage }}
      </p>
    </div>
  </div>

  <div *ngSwitchCase="'success'">
    <div role="alert" class="rounded border-l-4 border-green-900 bg-green-500 dark:bg-green-700 p-4">
      <div class="flex items-center gap-1 text-white">
        <fa-icon [icon]="faCheck"></fa-icon>
        <strong class="block font-extrabold text-lg">{{ alertTitle }}</strong>
      </div>

      <p class="mt-2 text-sm text-white">
        {{ alertMessage }}
      </p>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface PositionNames {
  [key: string]: string;

  A1: string;
  A2: string;
  A3: string;
  A4: string;
  B1: string;
  B2: string;
  B3: string;
  B4: string;
  C1: string;
  C2: string;
  C3: string;
  C4: string;
  D1: string;
  D2: string;
  D3: string;
  D4: string;
  E1: string;
}

// The InteractiveAxleComponent represents a component for an interactive axle within a vehicle schematic
@Component({
  selector: 'app-interactive-axle',
  templateUrl: './interactive-axle.component.html',
  styleUrls: ['./interactive-axle.component.scss']
})
export class InteractiveAxleComponent implements OnInit {
  private _vehicle: any; // Private vehicle data

  hoverWheel: number = -1; // Initialize the hovered wheel number to -1 (no wheel is being hovered over)

  // Event emitter for when a wheel is selected
  @Output() wheelSelected = new EventEmitter<any>();
  @Input() jobItemsIndex: number = 0;
  @Input() vehicleGroup!: any; // Add vehicleGroup input

  // Input setter and getter for vehicle data
  @Input() set vehicle(value: any) {
    this._vehicle = value;
    this.setValues(); // Call setValues function whenever vehicle data is updated
  }

  get vehicle(): any {
    return this._vehicle;
  }

  // Input property for axle ID
  @Input() axleId!: string;

  // Define axle type and wheel variables
  axleType!: string;
  wheel1!: string;
  wheel2!: string;
  wheel3!: string;
  wheel4!: string;

  positionNames: PositionNames = {
    A1: '',
    A2: '',
    A3: '',
    A4: '',
    B1: '',
    B2: '',
    B3: '',
    B4: '',
    C1: '',
    C2: '',
    C3: '',
    C4: '',
    D1: '',
    D2: '',
    D3: '',
    D4: '',
    E1: 'SPARE'
  };

  constructor() {}

  ngOnInit(): void {}

  // Set axle type and wheel values based on vehicle data
  setValues() {
    this.axleType = this.vehicle.vehicleLayout[`vehicleAxle${this.axleId}Axle`]?.vehicleAxleDescription;
    this.wheel1 = this.vehicle.vehicleLayout[`vehiclePos${this.axleId}1`];
    this.wheel2 = this.vehicle.vehicleLayout[`vehiclePos${this.axleId}2`];
    this.wheel3 = this.vehicle.vehicleLayout[`vehiclePos${this.axleId}3`];
    this.wheel4 = this.vehicle.vehicleLayout[`vehiclePos${this.axleId}4`];
    this.setPositionNames();
  }

  setPositionNames() {
    const axleIdToNumber: Record<string, number> = { A: 1, B: 2, C: 3, D: 4, E: 5 };

    for (const axleId in axleIdToNumber) {
      const wheel2 = this.vehicle.vehicleLayout[`vehiclePos${axleId}2`];
      const wheel3 = this.vehicle.vehicleLayout[`vehiclePos${axleId}3`];

      const axleNumber = axleIdToNumber[axleId];

      if (wheel2 == null && wheel3 == null) {
        this.positionNames[axleId + '1'] = `NS${axleNumber}`;
        this.positionNames[axleId + '4'] = `OS${axleNumber}`;
      } else {
        this.positionNames[axleId + '1'] = `NS${axleNumber}O`;
        this.positionNames[axleId + '2'] = `NS${axleNumber}I`;
        this.positionNames[axleId + '3'] = `OS${axleNumber}I`;
        this.positionNames[axleId + '4'] = `OS${axleNumber}O`;
      }
    }
    this.positionNames.E1 = `SPARE`;
  }

  // Emit wheelSelected event and highlight selected wheel when a wheel is selected
  selectWheel(posNo: any) {
    this.wheelSelected.emit({
      positionCode: this.getPositionName(this.axleId + posNo),
      wheelNumber: posNo,
      axleId: this.axleId,
      axleType: this.axleType
    });
    this.highlightWheel(posNo);
  }

  getPositionName(position: any) {
    return this.positionNames[position] || '';
  }

  getWheelClass(wheelNumber: number): object {
    return {
      'highlightWheel cursor-pointer': this.hoverWheel == wheelNumber,
      'bg-red-500': this.isInJobItems(wheelNumber)
    };
  }

  // Set the hovered wheel number to the selected wheel number
  highlightWheel(wheelNumber: number) {
    this.hoverWheel = wheelNumber;
  }

  // Checks whether the wheel is in the current job items, so it can be highlighted red.
  isInJobItems(wheelNumber: number): boolean {
    const axlePosString: string = this.axleId + wheelNumber;

    const jobItemsArray = this.vehicleGroup.get('jobItems').value || [];

    // Check if the wheel is in the job items
    const foundItem = jobItemsArray?.find((item: any) => {
      return item.axlePos == axlePosString;
    });

    return !!foundItem;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTime } from 'luxon';
import {
  faCalendarAlt,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronUp,
  faInfoCircle,
  faShuttleVan,
  faTimes,
  faTruck
} from '@fortawesome/pro-regular-svg-icons';
import { selectedDateTimeSlot, TimeSlotDialogComponent } from './time-slot-dialog/time-slot-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export interface TimeSlot {
  label: string;
  id: number;
  driveInAcceptance?: boolean;
}

export type TimeSlotCategory = 'day' | 'night';

@Component({
  selector: 'app-vehicle-availability-selection',
  templateUrl: './vehicle-availability-selection.component.html',
  styleUrls: ['./vehicle-availability-selection.component.scss']
})
export class VehicleAvailabilitySelectionComponent {
  constructor(private dialog: MatDialog) {}

  @Input() dtmContactNo?: string;
  @Output() selectionChange = new EventEmitter<selectedDateTimeSlot[]>();

  currentMonth: DateTime = DateTime.now();
  selectedTimeSlots: selectedDateTimeSlot[] = [];

  protected readonly faCalendarAlt = faCalendarAlt;

  todayStart(): DateTime {
    return DateTime.now().startOf('day');
  }

  protected readonly faTimes = faTimes;
  protected readonly faShuttleVan = faShuttleVan;
  protected readonly faTruck = faTruck;

  emitSelection() {
    this.selectionChange.emit(this.selectedTimeSlots);
  }

  protected readonly faChevronDoubleLeft = faChevronDoubleLeft;
  protected readonly faChevronDoubleRight = faChevronDoubleRight;
  protected readonly faInfoCircle = faInfoCircle;

  get daysInMonth(): (DateTime | { isPlaceholder: boolean })[] {
    const days: (DateTime | { isPlaceholder: boolean })[] = [];
    const startDay = this.currentMonth.startOf('month');
    const endDay = this.currentMonth.endOf('month');

    // Get the weekday of the first day of the month (1 = Monday, ..., 7 = Sunday)
    const firstDayOfWeek = startDay.weekday;
    // Add leading empty cells
    for (let i = 1; i < firstDayOfWeek; i++) {
      days.push({ isPlaceholder: true });
    }

    for (let day = startDay; day <= endDay; day = day.plus({ days: 1 })) {
      days.push(day);
    }

    // Add trailing empty cells
    const lastDayOfWeek = endDay.weekday;
    for (let i = lastDayOfWeek + 1; i <= 7; i++) {
      days.push({ isPlaceholder: true });
    }

    return days;
  }

  isSelected(date: DateTime): boolean {
    return this.selectedTimeSlots.some((slot) => slot.date.equals(date));
  }

  nextMonth(): void {
    if (!this.nextMonthDisabled()) {
      this.currentMonth = this.currentMonth.plus({ months: 1 });
    }
  }

  previousMonth(): void {
    const now = DateTime.now();
    if (this.currentMonth.minus({ months: 1 }).startOf('month') >= now.startOf('month')) {
      this.currentMonth = this.currentMonth.minus({ months: 1 });
    }
  }

  nextMonthDisabled(): boolean {
    const maxMonth = DateTime.now().plus({ months: 3 }).endOf('month');
    return this.currentMonth.endOf('month') >= maxMonth;
  }

  selectDate(day: DateTime) {
    if (day >= DateTime.now().startOf('day')) {
      this.dialog
        .open(TimeSlotDialogComponent, { data: { selectedDate: day }, maxWidth: '90vw', height: 'auto', maxHeight: '90vh' })
        .afterClosed()
        .subscribe((result: selectedDateTimeSlot) => {
          if (result) {
            this.selectedTimeSlots = this.selectedTimeSlots.filter((slot) => !slot.date.equals(day));
            this.selectedTimeSlots.push(result);
            this.emitSelection();
          }
        });
    }
  }

  removeDate(index: number): void {
    this.selectedTimeSlots.splice(index, 1); // Remove the date at the specified index
  }

  removeTimeSlot(dateIndex: number, timeSlotIndex: number): void {
    this.selectedTimeSlots[dateIndex].timeSlots.splice(timeSlotIndex, 1); // Remove the timeslot
    if (this.selectedTimeSlots[dateIndex].timeSlots.length === 0) {
      this.removeDate(dateIndex); // Optionally remove the date if no timeslots are left
    }
  }

  protected readonly faChevronUp = faChevronUp;
  protected readonly faChevronDown = faChevronDown;

  isPlaceholder(day: DateTime | { isPlaceholder: boolean }): day is { isPlaceholder: boolean } {
    return (day as { isPlaceholder: boolean }).isPlaceholder !== undefined;
  }

  previousMonthDisabled(): boolean {
    const now = DateTime.now();
    return this.currentMonth.minus({ months: 1 }).startOf('month') < now.startOf('month');
  }
}

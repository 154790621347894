<!-- This is the modal/dialog component -->
<div
  class="fixed inset-0 z-10 overflow-y-auto animate__animated animate__fadeIn"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true">
  <div class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:items-center sm:p-0">
    <!-- This is the background overlay -->
    <div class="fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <!-- This is the modal content -->
    <div
      class="inline-block transform overflow-hidden rounded-md bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
      <!-- Modal header -->
      <div class="bg-gradient-to-r from-blue-900 to-blue-500 px-4 py-3 sm:px-6">
        <h3 class="text-lg font-extrabold text-white" id="modal-title">Confirm Vehicle Details</h3>
      </div>

      <!-- Modal body -->
      <div class="bg-white px-6 py-6 shadow rounded-lg">
        <div class="w-full sm:mt-0">
          <!-- Vehicle Details -->
          <div>
            <p class="text-gray-500 font-semibold">
              Make:
              <span class="text-gray-900">{{ data.vehicle.vehicleMake }}</span>
            </p>
            <p class="text-gray-500 font-semibold">
              Model:
              <span class="text-gray-900">{{ data.vehicle.vehicleModelDr }}</span>
            </p>
            <p class="text-gray-500 font-semibold">
              Colour:
              <span class="text-gray-900">{{ data.vehicle.vehicleColour }}</span>
            </p>
            <p class="text-gray-500 font-semibold">
              Axle Configuration:
              <span class="text-gray-900">{{ data.vehicle.axleConfiguration }}</span>
            </p>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="justify-between bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <!-- Confirm Button -->
        <button
          (click)="onConfirm()"
          type="button"
          class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
          Confirm
        </button>

        <!-- Cancel Button -->
        <button
          (click)="onCancel()"
          type="button"
          class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>

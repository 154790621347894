import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LocatedComponent } from './components/located/located.component';

const routes: Routes = [
  { path: '', redirectTo: 'ext/booking', pathMatch: 'full' },
  // { path: "landing", component: LandingComponent },
  // Disabled for now until we further develop the app
  // {
  //   path: 'app',
  //   loadChildren: () =>
  //     import('./authenticated/authenticated.module').then(
  //       (m) => m.AuthenticatedModule
  //     ),
  // },
  {
    path: 'ext',
    loadChildren: () => import('./external/external.module').then((m) => m.ExternalModule)
  },
  { path: 'booking', redirectTo: 'ext/booking', pathMatch: 'full' },
  {
    path: 'locate',
    loadChildren: () => import('./locate/locate.module').then((m) => m.LocateModule)
  },
  { path: 'located', component: LocatedComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

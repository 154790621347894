import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { InteractiveAxleComponent } from './interactive-axle/interactive-axle.component';
import { MatDialog } from '@angular/material/dialog';
import { WheelInputDialogComponent } from './wheel-input-dialog/wheel-input-dialog.component';

@Component({
  selector: 'app-interactive-schematic',
  templateUrl: './interactive-schematic.component.html',
  styleUrls: ['./interactive-schematic.component.scss']
})
export class InteractiveSchematicComponent implements OnInit {
  // Input properties from parent component
  @Input() vehicle: any; // The vehicle object data
  @Input() jobItemIndex!: number;
  @Output() itemsChange = new EventEmitter<any[]>();
  @Input() vehicleGroup!: any;
  @Input() jobType: 'Services' | 'Tyres' = 'Tyres';

  // Define axle positions
  axlePos = ['A', 'B', 'C', 'D', 'E'];

  // Query all InteractiveAxleComponent children elements
  @ViewChildren(InteractiveAxleComponent) axles!: QueryList<InteractiveAxleComponent>;

  // Inject MatDialog service for opening dialog boxes
  constructor(private dialog: MatDialog) {}

  // On initialization, call the setVehicle function with the vehicle object data
  ngOnInit(): void {
    this.setVehicle(this.vehicle);
  }

  // Set the vehicle object data and initialize vehicle positions
  setVehicle = (vehicleData: any) => {
    if (vehicleData != null) {
      // Loop through each axle position
      this.axlePos.forEach((j, i) => {
        // If vehicle position data is not already set, initialize it to 1
        vehicleData.vehicleLayout[`vehiclePosData${j}${i + 1}`] = 1;
      });
    }
    // Set the vehicle object data
    this.vehicle = vehicleData;
  };

  // Check if a certain axle is used based on its ID
  isUsed = (axleId: string) => {
    for (let i = 1; i <= 4; i++) {
      // If the axle position is not null, return true
      if (this.vehicle?.vehicleLayout[`vehiclePos${axleId}${i}`] != null) {
        return true;
      }
    }
    // If none of the axle positions is used, return false
    return false;
  };

  // Event handler for when a wheel is clicked
  wheelClicked = (event: any) => {
    event.customerFleetListId = this.vehicle.customerFleetListId;
    event.vehicle = this.vehicle;
    event.jobType = this.jobType;
    // Open a dialog box with the WheelInputDialogComponent and pass the event data to it
    this.dialog
      .open(WheelInputDialogComponent, { panelClass: 'no-padding-dialog', data: event })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // Directly update the jobItems in the FormGroup
          const jobItems = this.vehicleGroup.get('jobItems').value;
          jobItems.push(result);
          this.vehicleGroup.get('jobItems').setValue(jobItems);
        }
      });
  };
}

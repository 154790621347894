import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSave, faSpinner, faUpload, IconDefinition } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent implements OnInit {
  @Input() buttonLabel: string = 'Save';
  @Input() isSaving: boolean = false;
  @Input() inProgressMessage: string = 'Saving...';
  @Output() onClick = new EventEmitter<void>();
  @Input() type: string = 'submit';
  @Input() disabled: boolean | undefined = false;

  faSpinner: IconDefinition = faSpinner;
  faSave: IconDefinition = faSave;
  faUpload: IconDefinition = faUpload;
  buttonIcon: IconDefinition | null = null;

  ngOnInit() {
    switch (this.buttonLabel) {
      case 'Save':
        this.buttonIcon = this.faSave;
        break;
      case 'Upload':
        this.buttonIcon = this.faUpload;
        break;
      default:
        this.buttonIcon = null;
    }
  }

  handleClick() {
    this.onClick.emit();
  }
}

import { Component, Input } from '@angular/core';
import {
  faCarSide,
  faFarm,
  faGear,
  faMotorcycle,
  faNotes,
  faTractor,
  faTrailer,
  faTruck,
  faTruckFront,
  faWrench
} from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-selected-vehicle',
  templateUrl: './selected-vehicle.component.html',
  styleUrls: ['./selected-vehicle.component.scss']
})
export class SelectedVehicleComponent {
  @Input() vehicleData: any;
  faNotes = faNotes;
  faWrench = faWrench;

  getIcon(vehicleClassification: string) {
    switch (vehicleClassification) {
      case 'AGRICULTURAL':
        return faFarm;

      case 'CAR':
        return faCarSide;

      case 'HORSEBOX':
        return faTrailer;

      case 'MOTORCYCLE':
        return faMotorcycle;

      case 'PLANT':
        return faGear;

      case 'TRACTOR':
        return faTractor;

      case 'TRAILER':
        return faTrailer;

      case 'VAN':
        return faTruckFront;

      default:
        return faTruck;
    }
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'card-header',
  template: `
    <div class="py-3 px-6 border-b border-gray-300 bg-gray-100 rounded-t-md text-blue-500 ">
      <h4 class="m-0 text-blue-500  font-bold">
        <ng-content></ng-content>
      </h4>
    </div>
  `
})
export class CardHeaderComponent {}

@Component({
  selector: 'card-body',
  template: `
    <div class="px-6 py-4 h-full">
      <ng-content></ng-content>
    </div>
  `
})
export class CardBodyComponent {}

@Component({
  selector: 'card-footer',
  template: `
    <div class="py-3 px-6 border-t border-gray-300 ">
      <ng-content></ng-content>
    </div>
  `
})
export class CardFooterComponent {}

@Component({
  selector: 'card',
  template: `
    <div [ngClass]="{ 'h-full': fullHeight }" class="block shadow border rounded-md border-gray-300 bg-white ">
      <ng-content select="card-header"></ng-content>
      <ng-content select="card-body"></ng-content>
      <ng-content></ng-content>
      <ng-content select="card-footer"></ng-content>
    </div>
  `
})
export class CardComponent {
  @Input() fullHeight!: boolean;
}

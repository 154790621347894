import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookingComponent } from './booking/booking.component';
import { BookingErrorComponent } from './booking/booking-error/booking-error.component';
import { BookingSuccessComponent } from './booking/booking-success/booking-success.component';
import { FleetAuditBookingConfirmationComponent } from './booking/fleet-audit-booking-confirmation/fleet-audit-booking-confirmation.component';

const routes: Routes = [
  { path: 'booking', component: BookingComponent },
  { path: 'booking/error', component: BookingErrorComponent },
  { path: 'booking/success', component: BookingSuccessComponent },
  { path: 'fleet-audit-booking/:fleetAuditGuid', component: FleetAuditBookingConfirmationComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExternalRoutingModule {}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/common/layout/header/header.component';
import { FooterComponent } from './components/common/layout/footer/footer.component';
import { LandingComponent } from './components/landing/landing.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { LocatedComponent } from './components/located/located.component';
import { ExternalModule } from './external/external.module';
import { InsightsService } from './services/insights.service';

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, LandingComponent, LocatedComponent],
  imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule, HttpClientModule, ExternalModule],
  bootstrap: [AppComponent],
  exports: [FooterComponent],
  providers: [InsightsService]
})
export class AppModule {}

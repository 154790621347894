import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { ExternalRoutingModule } from './external-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BookingComponent } from './booking/booking.component';
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatError, MatFormField, MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { SelectedVehicleComponent } from './booking/selected-vehicle/selected-vehicle.component';
import { SelectVehicleComponent } from './booking/select-vehicle/select-vehicle.component';
import { MultipleJobsCreatedDialogComponent } from './booking/multiple-jobs-created-dialog/multiple-jobs-created-dialog.component';
import { IdealAddressSearchComponent } from '../shared/ideal-address-search/ideal-address-search.component';
import { AlertComponent } from '../shared/alert/alert.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SaveButtonComponent } from '../shared/save-button/save-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CardModule } from '../shared/card/card.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule, MatOption } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStep, MatStepLabel, MatStepperModule } from '@angular/material/stepper';
import { BookingErrorComponent } from './booking/booking-error/booking-error.component';
import { InteractiveSchematicModule } from './booking/interactive-schematic/interactive-schematic.module';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { PageTitleHeaderComponent } from '../shared/page-title-header/page-title-header.component';
import { BookingSuccessComponent } from './booking/booking-success/booking-success.component';
import { VehicleConfirmationDialogComponent } from './booking/vehicle-confirmation-dialog/vehicle-confirmation-dialog.component';
import { FleetAuditBookingConfirmationComponent } from './booking/fleet-audit-booking-confirmation/fleet-audit-booking-confirmation.component';
import { CookieService } from 'ngx-cookie-service';
import { VehicleAvailabilitySelectionComponent } from './booking/vehicle-availability-selection/vehicle-availability-selection.component';
import { MatCalendar } from '@angular/material/datepicker';
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS, MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MatRipple } from '@angular/material/core';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { TimeSlotDialogComponent } from './booking/vehicle-availability-selection/time-slot-dialog/time-slot-dialog.component';
import { UppercaseDirective } from '../shared/uppercase.directive';

@NgModule({
  declarations: [
    BookingComponent,
    SelectedVehicleComponent,
    SelectVehicleComponent,
    MultipleJobsCreatedDialogComponent,
    IdealAddressSearchComponent,
    AlertComponent,
    SaveButtonComponent,
    BookingErrorComponent,
    ConfirmationDialogComponent,
    PageTitleHeaderComponent,
    BookingSuccessComponent,
    VehicleConfirmationDialogComponent,
    FleetAuditBookingConfirmationComponent,
    VehicleAvailabilitySelectionComponent,
    TimeSlotDialogComponent,
    UppercaseDirective
  ],
  imports: [
    CommonModule,
    ExternalRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatExpansionModule,
    MatChipsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTooltipModule,
    CardModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatDialogModule,
    MatStepperModule,
    FontAwesomeModule,
    InteractiveSchematicModule,
    MatCalendar,
    MatLuxonDateModule,
    MatRipple,
    MatSelectionList,
    MatListOption,
    MatRadioGroup,
    MatRadioButton,
    AsyncPipe,
    FaIconComponent,
    FaIconComponent,
    FaIconComponent,
    FormsModule,
    InteractiveSchematicModule,
    MatButton,
    MatError,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    MatStep,
    MatStepLabel,
    NgIf,
    ReactiveFormsModule
  ],
  exports: [SaveButtonComponent, SelectVehicleComponent],
  providers: [
    CookieService,
    { provide: DateAdapter, useClass: LuxonDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class ExternalModule {}

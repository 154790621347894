import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InteractiveSchematicComponent } from './interactive-schematic/interactive-schematic.component';
import { InteractiveAxleComponent } from './interactive-schematic/interactive-axle/interactive-axle.component';
import { AddedJobItemsComponent } from './added-job-items/added-job-items.component';
import { WheelInputDialogComponent } from './interactive-schematic/wheel-input-dialog/wheel-input-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { CardModule } from '../../../shared/card/card.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [InteractiveSchematicComponent, InteractiveAxleComponent, AddedJobItemsComponent, WheelInputDialogComponent],
  exports: [InteractiveSchematicComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    CardModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatTooltipModule,
    FontAwesomeModule,
    MatCheckboxModule
  ]
})
export class InteractiveSchematicModule {}

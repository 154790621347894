<div class="animate__animated animate__fadeIn" *ngIf="fleetAuditDetails; else loading">
  <!-- Hero Section -->
  <section class="w-full bg-gradient-to-t from-blue-500 to-blue-900 text-white">
    <div class="mx-auto max-w-screen-xl px-4 py-5 sm:px-6 lg:px-8">
      <div class="text-center">
        <h1 class="m-0 text-3xl font-extrabold leading-tight sm:text-5xl md:text-5xl">Fleet Audit - Confirm Booking Request</h1>
        <p class="mt-4 md:mt-2 md:text-xl">Please complete the form below and we will be in touch to confirm your appointment.</p>
      </div>
    </div>
  </section>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="absolute -z-10">
    <path
      fill="#009fe3"
      fill-opacity="1"
      d="M0,160L60,170.7C120,181,240,203,360,197.3C480,192,600,160,720,176C840,192,960,256,1080,256C1200,256,1320,192,1380,160L1440,128L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
  </svg>

  <div class="page-wrapper">
    <form [formGroup]="bookingForm" (ngSubmit)="onSubmit()" class="mx-auto max-w-screen-lg">
      <card>
        <card-header>Confirm your Booking Request</card-header>
        <card-body>
          <!-- Additional Information -->
          <div class="mb-4">
            <p>
              Thank you for taking the time to confirm your booking. Please confirm the availability for vehicle registration
              <strong>{{ fleetAuditDetails.vehicleReg }}</strong>
              .
            </p>
          </div>
        </card-body>
        <card-footer>
          <!--Vehicle Availability Component-->
          <app-vehicle-availability-selection (selectionChange)="handleAvailabilitySelection($event)"></app-vehicle-availability-selection>

          <div class="md:mt-2">
            <h2 class="my-4 inline-block text-xl font-extrabold text-blue-500">Additional Details</h2>
            <div class="grid grid-cols-1 gap-2 md:grid-cols-2">
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Order Number (If known)</mat-label>
                <input matInput formControlName="customerOrderNumber" maxlength="30" />
              </mat-form-field>

              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Defect Number (If known)</mat-label>
                <input matInput formControlName="customerDefectNumber" maxlength="30" />
              </mat-form-field>

              <mat-form-field class="w-full md:col-span-2" appearance="outline">
                <mat-label>Comments</mat-label>
                <textarea matInput formControlName="comments" maxlength="150"></textarea>
                <mat-hint>150 Characters Max</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </card-footer>
        <card-footer>
          <!-- Submit Button -->
          <div class="flex justify-center">
            <button
              type="submit"
              [disabled]="submittingBooking"
              class="w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md transition-colors duration-300 ease-in-out disabled:bg-gray-400">
              <span *ngIf="!submittingBooking; else submittingSpinner">Submit Booking Request</span>
            </button>
          </div>
        </card-footer>
      </card>
    </form>
  </div>
</div>

<ng-template #loading>
  <!-- Loading State, centered in the middle of the screen -->
  <div class="mt-24">
    <div class="flex flex-col justify-center text-center">
      <fa-icon [icon]="faTire" [size]="'4x'" [spin]="true" class="text-blue-500"></fa-icon>
      <span class="text-3xl font-bold text-gray-600 mt-2">Getting your booking details...</span>
    </div>
  </div>
</ng-template>

<ng-template #submittingSpinner>
  <fa-icon [icon]="faSpinner" [size]="'lg'" [spin]="true" class="text-white"></fa-icon>
  <span class="ml-2">Submitting...</span>
</ng-template>

<!--No Items/Notice to Add Items-->
<div class="flex flex-col justify-center items-center">
  <div class="overflow-x-auto w-full max-w-4xl">
    <div class="inline-block min-w-full align-middle">
      <div class="overflow-hidden">
        <div *ngIf="items.length > 0" class="animate__animated animate__fadeIn">
          <div class="flex justify-center mb-4">
            <span class="text-lg font-bold uppercase tracking-widest text-gray-800">Required Tyres / Services</span>
          </div>
          <div class="flex flex-col space-y-4">
            <div *ngFor="let item of items" class="overflow-hidden">
              <div
                class="flex items-center justify-between p-4 rounded-md border border-gray-300 shadow-sm hover:shadow-md transition-shadow duration-200">
                <!-- Item Details -->
                <div class="flex items-center space-x-4">
                  <fa-icon [icon]="faTireFlat" size="2x" class="text-blue-600"></fa-icon>
                  <div>
                    <div class="font-bold text-gray-900">
                      {{ item.tyreSize }}
                      <span *ngIf="item.loadRating > -1">{{ item.loadRating }}{{ item.speedRating }}</span>
                    </div>
                    <div class="text-gray-600">
                      {{ item.positionCode }}
                    </div>
                    <div class="text-sm text-gray-500">{{ item.removalReason.name || item.nonTyreService.name }}</div>
                  </div>
                </div>

                <!-- Action Button -->
                <button
                  (click)="removeItem(item)"
                  class="rounded bg-transparent p-2 text-red-700 hover:bg-red-500 hover:text-white transition-colors duration-200">
                  <fa-icon [icon]="faTrash" class="text-lg"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="items.length === 0" class="animate__animated animate__fadeIn text-center mt-4">
          <span class="text-gray-500">No tyres selected. Please add tyres to proceed with the job.</span>
        </div>
      </div>
    </div>
  </div>
</div>

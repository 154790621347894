<div class="flex flex-col md:flex-row justify-evenly gap-4 md:gap-8 w-full">
  <!--   Schematic Components -->
  <div *ngIf="vehicle && vehicle.vehicleLayout" class="py-4 flex-1">
    <div class="flex flex-col gap-8 justify-center">
      <div class="text-center font-bold tracking-widest">FRONT</div>
      <app-interactive-axle
        [jobItemsIndex]="jobItemIndex"
        [vehicle]="vehicle"
        axleId="A"
        *ngIf="isUsed('A')"
        (wheelSelected)="wheelClicked($event)"
        [vehicleGroup]="vehicleGroup"></app-interactive-axle>
      <app-interactive-axle
        [jobItemsIndex]="jobItemIndex"
        [vehicle]="vehicle"
        axleId="B"
        *ngIf="isUsed('B')"
        (wheelSelected)="wheelClicked($event)"
        [vehicleGroup]="vehicleGroup"></app-interactive-axle>
      <app-interactive-axle
        [jobItemsIndex]="jobItemIndex"
        [vehicle]="vehicle"
        axleId="C"
        *ngIf="isUsed('C')"
        (wheelSelected)="wheelClicked($event)"
        [vehicleGroup]="vehicleGroup"></app-interactive-axle>
      <app-interactive-axle
        [jobItemsIndex]="jobItemIndex"
        [vehicle]="vehicle"
        axleId="D"
        *ngIf="isUsed('D')"
        (wheelSelected)="wheelClicked($event)"
        [vehicleGroup]="vehicleGroup"></app-interactive-axle>
      <app-interactive-axle
        [jobItemsIndex]="jobItemIndex"
        [vehicle]="vehicle"
        axleId="E"
        *ngIf="isUsed('E')"
        (wheelSelected)="wheelClicked($event)"
        [vehicleGroup]="vehicleGroup"></app-interactive-axle>
      <div class="text-center font-bold tracking-widest">REAR</div>
    </div>
  </div>
  <!--   End Schematic Components -->

  <!--Added Job Items-->
  <div class="align-middle flex-1">
    <div class="flex flex-col justify-center">
      <app-added-job-items [vehicleGroup]="vehicleGroup"></app-added-job-items>
    </div>
  </div>
</div>

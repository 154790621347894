import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectListViewModel, TyreSizeViewModel } from '../../../../shared/tams-api';

const url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class InteractiveSchematicService {
  // Create BehaviorSubjects to cache the data
  private defectReasonsSubject = new BehaviorSubject<any>(null);
  private productSizesSubject = new BehaviorSubject<any>(null);
  private nonTyreServicesSubject = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  getProductSizes(customerFleetListId: number): Observable<TyreSizeViewModel[]> {
    // Check if product sizes are already cached, if not, fetch and cache them
    if (!this.productSizesSubject.value) {
      this.http.get<any>(`${url}DriverPortal/tyresizes/${customerFleetListId}`).subscribe((data) => {
        this.productSizesSubject.next(data);
      });
    }

    return this.productSizesSubject.asObservable();
  }

  getDefectReasons(): Observable<SelectListViewModel[]> {
    // Check if defect reasons are already cached, if not, fetch and cache them
    if (!this.defectReasonsSubject.value) {
      this.http.get<any>(`${url}DriverPortal/defectreasons`).subscribe((data) => {
        this.defectReasonsSubject.next(data);
      });
    }

    return this.defectReasonsSubject.asObservable();
  }

  getNonTyreServices(): Observable<SelectListViewModel[]> {
    // Check if non-tyre services are already cached, if not, fetch and cache them
    if (!this.nonTyreServicesSubject.value) {
      this.http.get<any>(`${url}DriverPortal/nontyreservices`).subscribe((data) => {
        this.nonTyreServicesSubject.next(data);
      });
    }

    return this.nonTyreServicesSubject.asObservable();
  }
}

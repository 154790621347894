import { Component, Input } from '@angular/core';
import { faTrash, faTireFlat } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-added-job-items',
  templateUrl: './added-job-items.component.html',
  styleUrls: ['./added-job-items.component.scss']
})
export class AddedJobItemsComponent {
  constructor() {}

  faTrash: IconDefinition = faTrash;
  faTireFlat: IconDefinition = faTireFlat;

  @Input() jobItemsIndex!: number;
  @Input() vehicleGroup!: any;

  get items() {
    return this.vehicleGroup.get('jobItems').value;
  }

  removeItem(item: any) {
    const jobItems = this.vehicleGroup.get('jobItems').value;
    const index = jobItems.indexOf(item);
    if (index > -1) {
      jobItems.splice(index, 1);
      this.vehicleGroup.get('jobItems').setValue(jobItems);
    }
  }
}

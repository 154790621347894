<card>
  <card-body>
    <div class="mb-2">
      <h2 class="text-2xl font-extrabold text-blue-500">Preferred Time Slots</h2>
      <p class="text-sm text-gray-500">
        Select your preferred time slots for
        <span class="font-bold text-blue-700">{{ selectedDateString }}</span>
        .
      </p>
    </div>
    <div class="h-0.5 bg-gradient-to-r from-blue-500 to-transparent w-full mb-4"></div>

    <!-- Time Slots List -->
    <div class="flex border-b gap-x-4">
      <button
        class="flex-1 py-2 text-center font-medium rounded-t-md"
        [ngClass]="{ 'bg-blue-500 text-white': activeCategory === 'day', 'hover:bg-blue-100': activeCategory !== 'day' }"
        (click)="selectCategory('day')">
        <fa-icon [icon]="faSunAlt"></fa-icon>
        Day
      </button>
      <button
        class="flex-1 py-2 text-center font-medium rounded-t-md"
        [ngClass]="{ 'bg-blue-500 text-white': activeCategory === 'night', 'hover:bg-blue-100': activeCategory !== 'night' }"
        (click)="selectCategory('night')">
        <fa-icon [icon]="faMoon"></fa-icon>
        Night*
      </button>
    </div>

    @if (activeCategory === 'night' || AdditionalChargeMessage) {
      <div class="bg-amber-500 border border-amber-400 text-gray-800 px-4 py-3 rounded relative mt-2" role="alert">
        @if (activeCategory === 'night') {
          <span class="block sm:inline">Night bookings incur an additional charge.</span>
        } @else {
          <span class="block sm:inline">{{ AdditionalChargeMessage }}</span>
        }
      </div>
    }

    <!-- Time Slots List -->
    <div class="time-slots-list">
      <mat-selection-list #timeSlotsList [(ngModel)]="selectedTimeSlots" class="border-0 rounded-md" (selectionChange)="resetError()">
        @if (filteredTimeSlots[activeCategory].length === 0) {
          @if (activeCategory === 'day') {
            <p>No available day slots remaining for today.</p>
            <p>Please check the available night slots or select another date.</p>
          } @else {
            No night slots remaining, please select another date.
          }
        }
        @for (slot of filteredTimeSlots[activeCategory]; track slot.label) {
          <div
            class="border border-gray-300 rounded-md my-2"
            [hidden]="activeCategory === 'day' && !showAdditionalDaySlots && slot.id >= 41 && slot.id <= 44 && arePrimarySlotsAvailable()">
            <mat-list-option color="primary" [value]="slot">
              <span class="font-bold text-xs sm:text-base">{{ slot.label }}</span>
              @if (activeCategory === 'night') {
                *
              }
            </mat-list-option>
          </div>
        }
      </mat-selection-list>
    </div>
    @if (arePrimarySlotsAvailable()) {
      @if (activeCategory === 'day' && !showAdditionalDaySlots) {
        <div class="text-center my-2">
          <button class="text-blue-500 hover:text-blue-700" (click)="toggleAdditionalDaySlots()">See additional slots</button>
        </div>
      }
      @if (activeCategory === 'day' && showAdditionalDaySlots) {
        <div class="text-center my-2">
          <button class="text-blue-500 hover:text-blue-700" (click)="toggleAdditionalDaySlots()">Hide additional slots</button>
        </div>
      }
      @if (noSelectionError) {
        <mat-error>Please select at least one time slot.</mat-error>
      }
    }

    <!--Drive In Appointment Acceptance-->
    @if (filteredTimeSlots[activeCategory].length != 0) {
      <mat-checkbox color="primary" [(ngModel)]="driveInAcceptance">Accept drive-in bookings</mat-checkbox>
    }
  </card-body>

  <card-footer>
    <!--A cancel button and a confirm button, mobile responsive-->
    <div class="flex flex-col sm:flex-row gap-2">
      <button class="flex-1 py-2 text-center font-medium rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300" (click)="cancel()">
        Cancel
      </button>
      @if (filteredTimeSlots[activeCategory].length != 0) {
        <button
          class="flex-1 py-2 text-center font-medium rounded-md bg-blue-500 text-white hover:bg-blue-600"
          (click)="confirmSelection()">
          Confirm
        </button>
      }
    </div>
  </card-footer>
</card>

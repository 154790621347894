import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable()
export class InsightsService {
  private angularPlugin = new AngularPlugin();
  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: 'f4e88176-c83f-4455-8829-c485841a57f6',
      extensions: [this.angularPlugin],
      autoTrackPageVisitTime: true,
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router
        }
      }
    }
  });

  constructor(private router: Router) {
    this.appInsights.loadAppInsights();
  }

  // expose methods that can be used in components and services
  trackEvent(name: string): void {
    this.appInsights.trackEvent({ name });
  }

  trackTrace(message: string): void {
    this.appInsights.trackTrace({ message });
  }
}

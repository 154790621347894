<div class="flex flex-col min-h-screen">
  <header class="h-16">
    <app-header></app-header>
  </header>
  <main class="flex-grow">
    <router-outlet></router-outlet>
  </main>
  <footer class="w-full">
    <app-footer></app-footer>
  </footer>
</div>

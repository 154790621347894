import { Component } from '@angular/core';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-booking-success',
  templateUrl: './booking-success.component.html',
  styleUrl: './booking-success.component.scss'
})
export class BookingSuccessComponent {
  faCheckCircle = faCheckCircle;
  spRef: number | null = null;

  constructor(private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    this.spRef = navigation?.extras.state?.['spRef'];
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, Observable, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeolocationService } from '../../services/geolocation.service';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-ideal-address-search',
  templateUrl: './ideal-address-search.component.html',
  styleUrls: ['./ideal-address-search.component.scss']
})
export class IdealAddressSearchComponent implements OnInit, OnChanges {
  searchControl = new FormControl();
  filteredAddresses!: Observable<any[]> | null;
  suggestionsShown: boolean = false;
  resolvingAddress: boolean = false;
  searching: boolean = false;
  selectionMade: boolean = false;
  manuallyInputting: boolean = false;

  @Output() addressSelected = new EventEmitter<any>();
  @Input() showError: any;
  @Input() hintText: string | null = null;
  @Input() id: string = '';
  @Input() attemptedNext: boolean = false;
  placeholderText: string = 'Enter an Address/Postcode to begin searching...';

  constructor(private geolocationService: GeolocationService) {}

  get inputId() {
    return `${this.id}-input`;
  }

  get autocompleteId() {
    return `${this.id}-autocomplete`;
  }

  ngOnInit(): void {
    this.filteredAddresses = this.searchControl.valueChanges.pipe(
      debounceTime(300),
      tap((value) => {
        this.searching = true;
        // Check if the input is cleared
        if (value === '') {
          this.searching = false;
          this.emitNullValue();
        }
      }),
      switchMap((value) =>
        this.geolocationService.searchAddress(value).pipe(
          map((response) => {
            this.searching = false;
            this.suggestionsShown = true;
            return response?.result?.hits || [];
          })
        )
      )
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['attemptedNext'] && this.attemptedNext) {
      // Trigger validation for address search control
      if (!this.selectionMade) {
        this.searchControl.setErrors({ required: true });
      }
    }
  }

  displayFn(value: any): string {
    // Handle both the initial object and the full address string
    return typeof value === 'string' ? value : value?.suggestion || '';
  }

  resolveAddress(event: any): void {
    if (event.manualEntry) {
      this.allowManualEntry();
    } else {
      this.selectionMade = true;
      this.geolocationService.resolveAddress(event.id).subscribe(
        (data) => {
          this.resolvingAddress = false;
          const fullAddress = `${event.suggestion} (${data.result.postcode})`;
          this.searchControl.setValue(fullAddress, { emitEvent: false });

          this.addressSelected.emit({
            geolocationGUID: data.geolocationGuid,
            fullAddress: fullAddress,
            address: {
              line1: data.result.line_1,
              line2: data.result.line_2,
              line3: data.result.line_3,
              line4: data.result.post_town,
              county: data.result.county,
              postcode: data.result.postcode,
              country: data.result.country
            }
          });
        },
        (error) => {
          console.error(error);
          this.resolvingAddress = false;
        }
      );
    }
  }

  private emitNullValue(): void {
    this.addressSelected.emit(null);
    this.selectionMade = false;
    this.searchControl.setErrors(null);
  }

  private allowManualEntry() {
    this.searchControl.setValue('', { emitEvent: true });
    this.searching = false;
    // Ensure autocomplete does not trigger
    this.filteredAddresses = null;
    this.manuallyInputting = true;
    this.placeholderText = 'Manually enter the full address of the vehicle';
  }

  onInputBlur(): void {
    if (!this.selectionMade) {
      // Emit what has been typed manually in the input field
      this.addressSelected.emit({
        fullAddress: this.searchControl.value
      });
    }
  }

  protected readonly faInfoCircle = faInfoCircle;
}

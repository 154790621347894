<div
  title="Vehicle Information"
  class="vehicle-info mb-4 rounded-md py-2 px-6 bg-gradient-to-r from-blue-700 to-blue-500 border border-blue-900 shadow"
  *ngIf="vehicleData">
  <div class="flex flex-col sm:flex-row items-center">
    <fa-icon [icon]="getIcon(vehicleData.vehicleLayout?.vehicleClassification!)" class="text-white" size="2x"></fa-icon>
    <!--Standard Data Display if No Driveright Data Held-->
    <div class="mt-2 sm:mt-0 sm:ml-4 text-center sm:text-left" *ngIf="!vehicleData.vehicleMake">
      <span class="block text-lg sm:text-xl font-extrabold tracking-wide text-white">
        {{ vehicleData.customerFleetlistVehicleReg }} - {{ vehicleData.vehicleLayout?.vehicleClassification }}
      </span>
      <span class="text-md sm:text-lg font-semibold text-white">{{ vehicleData.vehicleLayout?.vehicleAxleConfigDescription }}</span>
    </div>

    <!--Driveright Data Display If Held-->
    <div class="mt-2 sm:mt-0 sm:ml-4 text-center sm:text-left" *ngIf="vehicleData.vehicleMake">
      <span class="block text-lg sm:text-xl font-extrabold tracking-wide text-white">
        {{ vehicleData.customerFleetlistVehicleReg }}
        - {{ vehicleData.vehicleColour + ' ' + vehicleData.vehicleMake + ' ' + vehicleData.vehicleModelDr }}
      </span>
      <span class="text-md sm:text-lg font-semibold text-white">{{ vehicleData.axleConfiguration }}</span>
    </div>
  </div>
</div>

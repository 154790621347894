import { Component, Input } from '@angular/core';
import { faExclamationTriangle, faInfoCircle, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  @Input() type: 'success' | 'warning' | 'error' | 'info' = 'success';
  @Input() alertTitle: string = '';
  @Input() alertMessage: string = '';

  faExclamation: IconDefinition = faExclamationTriangle;
  faInfo: IconDefinition = faInfoCircle;
  faCheck: IconDefinition = faCheck;
}

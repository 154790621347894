<button mat-raised-button [disabled]="isSaving || disabled" (click)="handleClick()" color="primary" [type]="type" class="w-full lg:w-auto">
  <ng-container *ngIf="!isSaving; else loading">
    <fa-icon [icon]="buttonIcon" *ngIf="buttonIcon"></fa-icon>
    {{ buttonLabel }}
  </ng-container>
  <ng-template #loading>
    <fa-icon [icon]="faSpinner" animation="spin"></fa-icon>
    {{ inProgressMessage }}
  </ng-template>
</button>

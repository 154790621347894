import { Injectable } from '@angular/core';
//@ts-ignore
import OsGridRef from 'geodesy/osgridref.js';
import { catchError, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const api = environment.api;

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  constructor(private http: HttpClient) {}
  convertOSGridRefToLatLong(eastings: number, northings: number) {
    const gridRef = new OsGridRef(eastings, northings);
    return gridRef.toLatLon();
  }

  searchAddress(addressString: string): Observable<any> {
    return this.http
      .get<any>(`${api}DriverPortal/location/searchAddress?addressString=${addressString}`)
      .pipe(catchError((error) => of([])));
  }

  resolveAddress(addressKey: string): Observable<any> {
    return this.http.get<any>(`${api}DriverPortal/location/resolveAddress/${addressKey}`).pipe(catchError((error) => of([])));
  }
}

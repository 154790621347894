<mat-form-field class="w-full" appearance="outline">
  <mat-label>Vehicle Location</mat-label>
  <input
    type="text"
    matInput
    [matAutocomplete]="auto"
    [formControl]="searchControl"
    [id]="inputId"
    [placeholder]="placeholderText"
    (blur)="onInputBlur()" />
  <mat-progress-bar mode="indeterminate" class="mb-4" *ngIf="searching"></mat-progress-bar>
  <mat-autocomplete
    #auto="matAutocomplete"
    [id]="autocompleteId"
    [displayWith]="displayFn"
    (optionSelected)="resolveAddress($event.option.value)"
    class="divide-y divide-gray-300">
    <!--For manual entry of address-->
    @if (!manuallyInputting && suggestionsShown) {
      <mat-option [value]="{ manualEntry: true }">
        <fa-icon class="text-blue-500" [icon]="faInfoCircle"></fa-icon>
        If your address is not listed below, click here to enter it manually.
      </mat-option>
    }
    <mat-option *ngFor="let address of filteredAddresses | async" [value]="address">
      {{ address.suggestion }}
    </mat-option>
  </mat-autocomplete>
  <mat-hint *ngIf="hintText">{{ hintText }}</mat-hint>
  <mat-error *ngIf="searchControl.invalid">Please select a valid location</mat-error>
</mat-form-field>

<div class="animate__animated animate__fadeIn">
  <section class="w-full bg-gradient-to-t from-red-500 to-red-900 text-white">
    <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
      <div class="text-center">
        <h1 class="text-3xl sm:text-5xl font-extrabold leading-tight md:text-6xl">
          {{ errorTitle }}
        </h1>
      </div>
    </div>
  </section>
  <div class="page-wrapper">
    <div class="flex flex-col items-center justify-center p-10 animate__animated animate__fadeIn">
      <div class="text-center">
        <p class="text-2xl mb-4">{{ errorMessage }}</p>
        <p class="text-xl font-bold text-blue-500">{{ errorAction }}</p>
      </div>

      <button class="mt-4" mat-raised-button (click)="goBack()">Return to Booking Request</button>
    </div>
  </div>
</div>

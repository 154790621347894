<card>
  <card-header>{{ data.confirmationTitle }}</card-header>
  <card-body>{{ data.confirmationMessage }}</card-body>
  <card-footer>
    <div class="flex flex-row justify-between">
      <button mat-raised-button (click)="closeDialog()">Cancel</button>
      <button mat-raised-button color="primary" (click)="confirmDialog()">Confirm</button>
    </div>
  </card-footer>
</card>

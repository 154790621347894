<div>
  <!-- Introductory Note -->
  <div *ngIf="dtmContactNo" class="mb-4 rounded-md border-l-4 border-blue-500 bg-blue-700 p-4 text-white">
    <p class="text-md">For any urgent jobs, kindly contact us directly on {{ dtmContactNo }}.</p>
  </div>

  <div class="grid gap-y-4 sm:divide-x sm:divide-gray-300 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-0">
    <!-- Date Selection Section -->
    <div>
      <div class="mb-4 text-center">
        <h2 class="text-xl font-bold text-gray-700">Select Your Preferred Dates</h2>
        <p class="text-sm text-gray-500">You can select more than one date</p>
      </div>
      <div class="mb-2 flex items-center justify-between">
        <button
          #previousMonthButton
          type="button"
          class="rounded bg-blue-500 px-4 py-2 text-white transition-colors hover:bg-blue-600"
          [ngClass]="{ 'bg-gray-300 cursor-not-allowed ': previousMonthDisabled() }"
          [disabled]="previousMonthDisabled()"
          (click)="previousMonth()">
          <fa-icon [icon]="faChevronDoubleLeft"></fa-icon>
        </button>
        <span class="text-lg font-semibold">{{ currentMonth.toFormat('MMMM yyyy') }}</span>
        <button
          type="button"
          class="rounded bg-blue-500 px-4 py-2 text-white transition-colors hover:bg-blue-600"
          [ngClass]="{ 'bg-gray-300 cursor-not-allowed ': nextMonthDisabled() }"
          [disabled]="nextMonthDisabled()"
          (click)="nextMonth()">
          <fa-icon [icon]="faChevronDoubleRight"></fa-icon>
        </button>
      </div>
      <div class="grid grid-cols-7 gap-1">
        <div class="text-center font-bold">Mon</div>
        <div class="text-center font-bold">Tue</div>
        <div class="text-center font-bold">Wed</div>
        <div class="text-center font-bold">Thu</div>
        <div class="text-center font-bold">Fri</div>
        <div class="text-center font-bold">Sat</div>
        <div class="text-center font-bold">Sun</div>
      </div>
      <div class="grid grid-cols-7 gap-1">
        <ng-container *ngFor="let day of daysInMonth">
          <ng-container *ngIf="!isPlaceholder(day); else emptyCell">
            <button
              type="button"
              class="cursor-pointer rounded border p-2 text-center hover:bg-blue-100"
              [ngClass]="{
                'bg-blue-500 text-white': isSelected(day) && day.month === currentMonth.month,
                'text-gray-500 opacity-50': day.month !== currentMonth.month || day < todayStart()
              }"
              (mousedown)="selectDate(day)"
              (keydown.enter)="selectDate(day)">
              {{ day.day }}
            </button>
          </ng-container>
          <ng-template #emptyCell>
            <div class="p-2"></div>
          </ng-template>
        </ng-container>
      </div>
    </div>

    <!-- Selection Summary List / Placeholder when empty-->
    <div *ngIf="selectedTimeSlots.length === 0" class="hidden flex-col items-center justify-center text-center sm:flex">
      <fa-icon [icon]="faCalendarAlt" class="text-6xl text-blue-500"></fa-icon>
      <p class="text-lg text-gray-500">Select a date on the calendar to select your first preferred date and time.</p>
    </div>
    <div *ngIf="selectedTimeSlots.length > 0" class="overflow-auto pb-4 animate__animated animate__fadeIn" style="max-height: 342px">
      <div class="mb-4 text-center">
        <h2 class="text-xl font-bold text-gray-700">Selected Dates & Timeslots</h2>
      </div>
      <ul class="mt-2 flex flex-col gap-4 sm:px-4">
        <li
          *ngFor="let slot of selectedTimeSlots; let i = index"
          class="rounded-lg border border-gray-300 bg-white p-4 shadow transition-shadow duration-300 hover:shadow-lg">
          <div class="flex cursor-pointer items-center justify-between" (click)="slot.expanded = !slot.expanded">
            <div class="flex-1 font-semibold text-gray-800">
              {{ slot.date.toFormat('EEEE dd/MM/yyyy') }}
              <span class="ml-2 text-sm text-gray-500">
                ({{ slot.timeSlots.length }} timeslot{{ slot.timeSlots.length === 1 ? '' : 's' }} selected)
              </span>
            </div>
            <fa-icon [icon]="slot.expanded ? faChevronUp : faChevronDown" class="text-gray-500"></fa-icon>
          </div>
          <div *ngIf="slot.expanded" class="flex flex-col gap-2">
            <div
              *ngFor="let time of slot.timeSlots; let j = index"
              class="flex items-center justify-between rounded-md p-2 transition-colors duration-200 hover:bg-gray-100">
              <div class="flex items-center gap-2">
                {{ time.label }}
                <span
                  *ngIf="time.driveInAcceptance"
                  class="inline-flex items-center rounded bg-green-500 px-2.5 py-0.5 font-medium text-white whitespace-nowrap">
                  <fa-icon [icon]="faTruck" class="text-sm mr-1"></fa-icon>
                  Drive In
                </span>
              </div>
              <fa-icon
                [icon]="faTimes"
                size="2x"
                class="cursor-pointer text-red-500"
                matTooltip="Remove timeslot"
                (click)="removeTimeSlot(i, j)"></fa-icon>
            </div>
          </div>
        </li>
        <li *ngIf="selectedTimeSlots.length === 1">
          <!--When 1 date is selected show an alert advising they can select more dates-->
          <div class="rounded-md border-l-4 border-blue-500 bg-blue-700 p-4 text-white animate__animated animate__fadeIn">
            <p class="text-md">
              <fa-icon [icon]="faInfoCircle"></fa-icon>
              You can select additional dates if required.
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

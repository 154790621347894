import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from '../booking.service';
import { FleetAuditViewModel } from '../../../shared/tams-api';
import { faSpinner, faTire } from '@fortawesome/pro-regular-svg-icons';
import { selectedDateTimeSlot } from '../vehicle-availability-selection/time-slot-dialog/time-slot-dialog.component';

@Component({
  selector: 'app-fleet-audit-booking-confirmation',
  templateUrl: './fleet-audit-booking-confirmation.component.html',
  styleUrls: ['./fleet-audit-booking-confirmation.component.scss']
})
export class FleetAuditBookingConfirmationComponent {
  submitAttempted: boolean = false;
  bookingForm: FormGroup;
  fleetAuditDetails: FleetAuditViewModel | null = null;
  fleetAuditGuid: string | null = null;
  readonly faTire = faTire;
  readonly faSpinner = faSpinner;
  submittingBooking: boolean = false;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private bookingService: BookingService,
    private router: Router
  ) {
    this.fleetAuditGuid = this.activatedRoute.snapshot.paramMap.get('fleetAuditGuid');
    this.retrieveAuditDetails(this.fleetAuditGuid!);
    this.bookingForm = this.fb.group({
      vehicleAvailability: new FormControl([], [Validators.required]),
      customerOrderNumber: [''],
      customerDefectNumber: [''],
      comments: ['']
    });
  }

  retrieveAuditDetails(fleetAuditGuid: string): void {
    this.bookingService.getFleetAuditDetails(fleetAuditGuid).subscribe({
      next: (fleetAuditDetails) => {
        this.fleetAuditDetails = fleetAuditDetails;
      },
      error: () => {
        // If they're using an invalid fleetAuditGuid, redirect them to the root page
        this.router.navigate(['/']);
      }
    });
  }

  onSubmit(): void {
    this.submitAttempted = true;
    if (this.bookingForm.controls['vehicleAvailability'].value.length === 0) {
      return;
    }

    this.submittingBooking = true;

    if (this.bookingForm.valid) {
      const submissionPayload = {
        fleetAuditGuid: this.fleetAuditGuid,
        ...this.bookingForm.value
      };

      this.bookingService.confirmFleetAuditBooking(submissionPayload).subscribe({
        next: (result) => {
          this.submittingBooking = false;
          this.router.navigate(['/ext/booking/success'], { state: { spRef: result } });
        },
        error: (error) => {
          this.submittingBooking = false;
          console.error(error);
        }
      });
    }
  }

  public handleAvailabilitySelection(selectedTimeSlots: selectedDateTimeSlot[]): void {
    this.bookingForm.patchValue({
      vehicleAvailability: selectedTimeSlots
    });
    console.log(this.bookingForm.value.vehicleAvailability);
  }
}

<form [formGroup]="jobItemForm" (ngSubmit)="confirm()">
  <card>
    <card-body class="dialog-body">
      <div class="mb-2">
        @if (data.jobType === 'Tyres') {
          <h2 class="text-2xl font-extrabold text-blue-500">Tyre & Defect Details</h2>
        } @else {
          <h2 class="text-2xl font-extrabold text-blue-500">Required Service</h2>
        }
        <p class="text-sm text-gray-500">
          Add a Job Item to Axle Position:
          <span class="font-bold text-blue-700">{{ data.positionCode }}</span>
          .
        </p>
      </div>
      <div class="mb-4 w-full bg-gradient-to-r from-blue-500 to-transparent h-0.5"></div>
      <div class="scrollable-content">
        <div class="rounded-md border border-gray-300 p-4 text-center scrollable-content">
          <h4 class="text-xl font-semibold text-gray-700" *ngIf="data.jobType === 'Tyres'">Tyre Defect</h4>
          <h4 class="text-xl font-semibold text-gray-700" *ngIf="data.jobType === 'Services'">Service Details</h4>
          <p *ngIf="data.jobType === 'Tyres'">Please select a Tyre Defect and Tyre Fitment for position {{ data.positionCode }}</p>
          <p *ngIf="data.jobType === 'Services'">Please select a Service for position {{ data.positionCode }}</p>

          <div class="mt-4 grid grid-cols-1">
            <mat-form-field appearance="outline" class="w-full" *ngIf="data.jobType === 'Tyres'">
              <mat-label>Select Tyre Defect</mat-label>
              <mat-select formControlName="removalReason">
                <mat-option *ngFor="let reason of removalReasons" [value]="reason">{{ reason.name }}</mat-option>
              </mat-select>
              <mat-error *ngIf="jobItemForm.get('removalReason')?.invalid">Please select a Tyre Defect</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-full" *ngIf="data.jobType === 'Services'">
              <mat-label>Select Service</mat-label>
              <mat-select formControlName="nonTyreService">
                <mat-option *ngFor="let service of nonTyreServices" [value]="service">{{ service.name }}</mat-option>
              </mat-select>
              <mat-error *ngIf="jobItemForm.get('nonTyreService')?.invalid">Please select a Service</mat-error>
            </mat-form-field>

            <div class="flex flex-col">
              <mat-form-field appearance="outline" class="w-full" *ngIf="showManualInput">
                <mat-label>[Tyre Size]/[Profile]R[Radius]</mat-label>
                <input type="text" matInput formControlName="tyreSize" [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let size of filteredSizes | async" [value]="size.productSizeShort">
                    {{ size.productSizeShort }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="jobItemForm.get('tyreSize')?.invalid && jobItemForm.get('tyreSize')?.touched">
                  Select a valid Tyre Size
                </mat-error>
                <mat-hint>Example: 235/65R18</mat-hint>
              </mat-form-field>
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-4">
              <mat-form-field appearance="outline" class="w-full" *ngIf="!jobItemForm.controls['ratingsUnknown'].value && showManualInput">
                <mat-label>Speed Rating</mat-label>
                <mat-select formControlName="speedRating">
                  <mat-option *ngFor="let rating of speedRatings" [value]="rating">{{ rating }}</mat-option>
                </mat-select>
                <mat-error *ngIf="jobItemForm.get('speedRating')?.invalid"> Please Select a Speed Rating </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="w-full" *ngIf="!jobItemForm.controls['ratingsUnknown'].value && showManualInput">
                <mat-label>Load Rating</mat-label>
                <input type="text" matInput formControlName="loadRating" placeholder="e.g. 120 or 120/125" maxlength="7" />
                <mat-error *ngIf="jobItemForm.get('loadRating')?.invalid">Enter a Load Rating</mat-error>
              </mat-form-field>

              <div class="w-full col-span-full">
                <mat-checkbox formControlName="ratingsUnknown" *ngIf="showManualInput" class="text-sm">
                  <span class="text-sm">Ratings Not Known</span>
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="scrollable-content mt-4">
          <!-- Suggested Fitments from DriveRight Data -->
          <div
            *ngIf="!showManualInput && (jobItemForm.controls['removalReason'].value || jobItemForm.controls['nonTyreService'].value)"
            class="rounded-md border border-gray-300 p-4 text-center animate__animated animate__fadeIn">
            <h4 class="text-xl font-semibold text-gray-700">Tyre Fitment</h4>
            <h5 class="font-medium text-gray-500 text-md" *ngIf="!selectedFitment">Recommended Fitments</h5>
            <h5 class="font-medium text-gray-500 text-md animate__animated animate__bounceIn" *ngIf="selectedFitment">Selected Fitment</h5>

            <div class="flex max-h-48 flex-col gap-y-4 overflow-y-scroll">
              <ng-container *ngFor="let fitment of fitments">
                <div
                  *ngIf="!selectedFitment || selectedFitment === fitment"
                  class="flex cursor-pointer items-center rounded-md border p-4 shadow-inner hover:shadow"
                  [ngClass]="{ 'border-2 border-blue-500': selectedFitment === fitment }"
                  (click)="selectFitment(fitment)">
                  <span class="ml-2 hidden sm:block">
                    <span
                      class="rounded-full bg-blue-500 px-2 py-1 text-sm font-bold uppercase text-white"
                      *ngIf="fitment.fitmentType === 'front'">
                      Front
                    </span>
                    <span
                      class="rounded-full bg-green-500 px-2 py-1 text-sm font-bold uppercase text-white"
                      *ngIf="fitment.fitmentType === 'rear'">
                      Rear
                    </span>
                  </span>
                  <div class="ml-2 text-left font-bold tracking-wider text-grey-500">
                    <span>{{ fitment.tyreSize }} {{ fitment.loadIndex }} {{ fitment.speedIndex }}</span>
                    <span *ngIf="fitment.rimSize || fitment.rimOffset" class="hidden text-xs sm:flex">
                      (Rim: {{ fitment.rimSize }}, Offset: {{ fitment.rimOffset }} )
                    </span>
                  </div>
                </div>
              </ng-container>
            </div>
            <mat-error *ngIf="jobItemForm.controls['selectedFitment'].hasError('required')">
              Select a Fitment or manually enter a tyre
            </mat-error>
          </div>
        </div>

        <!--Link to display recommendations again-->
        <div class="my-2 text-center" *ngIf="selectedFitment || showManualInput">
          <a class="cursor-pointer text-blue-600 hover:underline" (click)="selectedFitment = null; showManualInput = false">
            View Recommended Fitments
          </a>
        </div>
        <!-- Link to manual search -->
        <div class="my-2 text-center" *ngIf="!showManualInput">
          <a class="cursor-pointer text-blue-600 hover:underline" (click)="showManualInput = true">Manually Input a Tyre</a>
        </div>
      </div>
    </card-body>
    <card-footer>
      <!--A cancel button and a confirm button, mobile responsive-->
      <div class="flex flex-col gap-2 sm:flex-row">
        <button mat-dialog-close class="flex-1 rounded-md bg-gray-200 py-2 text-center font-medium text-gray-700 hover:bg-gray-300">
          Cancel
        </button>
        <button class="flex-1 rounded-md bg-blue-500 py-2 text-center font-medium text-white hover:bg-blue-600" type="submit">
          Confirm
        </button>
      </div>
    </card-footer>
  </card>
</form>

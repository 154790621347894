import { Component } from '@angular/core';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-booking-error',
  templateUrl: './booking-error.component.html',
  styleUrls: ['./booking-error.component.scss']
})
export class BookingErrorComponent {
  errorTitle: string = '';
  errorMessage: string = '';
  errorAction: string = '';

  constructor(private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    this.errorTitle = navigation?.extras.state?.['errorTitle'] || 'Error';
    this.errorMessage = navigation?.extras.state?.['errorMessage'] || 'An unexpected error occurred';
    this.errorAction = navigation?.extras.state?.['errorAction'] || 'Please try again';
  }

  faExlamationTriangle = faExclamationTriangle;

  goBack() {
    this.router.navigate(['/ext/booking']);
  }
}

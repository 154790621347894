import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DpVehicleViewModel } from '../../../shared/tams-api';

export interface DialogData {
  vehicle: DpVehicleViewModel;
}

@Component({
  selector: 'app-vehicle-confirmation-dialog',
  templateUrl: './vehicle-confirmation-dialog.component.html',
  styleUrls: ['./vehicle-confirmation-dialog.component.scss']
})
export class VehicleConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<VehicleConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(this.data.vehicle);
  }

  protected readonly confirm = confirm;
}

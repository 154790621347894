<footer class="relative bg-grey-600">
  <div class="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
    <span class="absolute inset-x-0 top-0 h-1 bg-gradient-to-r from-grey-500 via-blue-500 to-grey-500"></span>
    <nav aria-labelledby="footer-navigation">
      <div class="text-white text-xs">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:divide-x divide-grey-300">
          <div class="self-center w-full flex flex-row justify-center">
            <img class="h-36" alt="Direct Tyre Management Logo" src="../../../../../assets/images/dtm-logo-full-dark-bg.png" />
          </div>
          <div class="self-center px-6 hidden md:block">
            <!--            <p class="font-bold text-2xl">Support</p>-->
            <!--            <nav class="mt-2 flex flex-col space-y-2 text-sm text-gray-300">-->
            <!--              <a class="hover:opacity-75" href=""> Contact </a>-->
            <!--              <a class="hover:opacity-75" href=""> Another Link </a>-->
            <!--            </nav>-->
          </div>
          <div class="self-center px-6 hidden md:block">
            <!--            <p class="font-bold text-2xl">Other Links</p>-->
            <!--            <nav class="mt-2 flex flex-col space-y-2 text-sm text-gray-300">-->
            <!--              <a class="hover:opacity-75" href=""> One More Link </a>-->
            <!--              <a class="hover:opacity-75" href=""> Some Other Link </a>-->
            <!--            </nav>-->
          </div>
        </div>
        <div>
          <div class="mt-4 border-t border-grey-300 pt-4">
            <div class="text-sm text-gray-300 flex justify-center flex-col sm:flex-row sm:items-center sm:justify-between">
              <div class="flex gap-3 justify-center">
                <a class="hover:opacity-75" href="">Privacy Policy</a>
                <a class="hover:opacity-75" href="">Cookie Policy</a>
              </div>

              <p class="mt-4 sm:mt-0 text-center">
                © Copyright Direct Tyre Management Limited
                {{ year | date: 'yyyy' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</footer>

<div class="w-full flex flex-row justify-center">
  <!--Axle Diagram-->
  <div
    class="wrapper"
    [ngClass]="{ steer: axleType == 'STEER', 'flex flex-row justify-center': !wheel3 && !wheel4 }"
    *ngIf="axleType && (wheel1 || wheel2 || wheel3 || wheel4)">
    <!--  Position 1-->
    <div
      class="relative text-center text-white wheel"
      *ngIf="wheel1"
      tabindex="0"
      [ngClass]="{ 'highlightWheel cursor-pointer': hoverWheel == 1, jobItemTyre: isInJobItems(1) }"
      (click)="!isInJobItems(1) && selectWheel(1)"
      (mouseover)="highlightWheel(1)"
      (mouseleave)="hoverWheel = -1"
      (keydown.enter)="!isInJobItems(1) && selectWheel(1)"
      [matTooltip]="getPositionName(axleId + 1)"
      matTooltipPosition="above"></div>
    <!--  Position 2-->
    <div
      class="relative text-center text-white wheel"
      *ngIf="wheel2"
      tabindex="0"
      [ngClass]="{ 'highlightWheel cursor-pointer': hoverWheel == 2, jobItemTyre: isInJobItems(2) }"
      (click)="!isInJobItems(2) && selectWheel(2)"
      (mouseover)="highlightWheel(2)"
      (mouseleave)="hoverWheel = -1"
      (keydown.enter)="!isInJobItems(2) && selectWheel(2)"
      [matTooltip]="getPositionName(axleId + 2)"
      matTooltipPosition="above"></div>
    <div class="axle" *ngIf="wheel3 || wheel4">
      <div class="drive" *ngIf="axleType == 'DRIVE'"></div>
    </div>
    <!--  Position 3-->
    <div
      class="relative text-center text-white wheel"
      *ngIf="wheel3"
      tabindex="0"
      [ngClass]="{ 'highlightWheel cursor-pointer': hoverWheel == 3, jobItemTyre: isInJobItems(3) }"
      (click)="!isInJobItems(3) && selectWheel(3)"
      (mouseover)="highlightWheel(3)"
      (mouseleave)="hoverWheel = -1"
      (keydown.enter)="!isInJobItems(3) && selectWheel(3)"
      [matTooltip]="getPositionName(axleId + 3)"
      matTooltipPosition="above"></div>
    <!--  Position 4-->
    <div
      class="relative text-center text-white wheel"
      *ngIf="wheel4"
      tabindex="0"
      [ngClass]="{ 'highlightWheel cursor-pointer': hoverWheel == 4, jobItemTyre: isInJobItems(4) }"
      (click)="!isInJobItems(4) && selectWheel(4)"
      (mouseover)="highlightWheel(4)"
      (mouseleave)="hoverWheel = -1"
      (keydown.enter)="!isInJobItems(4) && selectWheel(4)"
      [matTooltip]="getPositionName(axleId + 4)"
      matTooltipPosition="above"></div>
  </div>
  <!--End Axle Diagram-->
</div>

<!--For 3 Wheelers / Forklifts/Moffetts-->
<div class="spare-wrapper" *ngIf="wheel1 && !wheel3 && !wheel4 && axleType">
  <div
    *ngIf="wheel1 && axleType"
    tabindex="0"
    (mouseover)="highlightWheel(1)"
    (mouseleave)="hoverWheel = -1"
    (click)="!isInJobItems(1) && selectWheel(1)"
    (keydown.enter)="!isInJobItems(1) && selectWheel(1)"
    style="width: 18%"
    [matTooltip]="getPositionName(axleId + 1)"
    matTooltipPosition="above"></div>
</div>
<!--End 3 Wheeler Divs-->

<div class="spare-wrapper" *ngIf="wheel1 && !axleType">
  <div
    class="spare"
    *ngIf="wheel1"
    tabindex="0"
    [ngClass]="{ 'highlightWheel cursor-pointer': hoverWheel == 1 && !axleType, jobItemTyre: isInJobItems(1) }"
    (mouseover)="highlightWheel(1)"
    (mouseleave)="hoverWheel = -1"
    (click)="!isInJobItems(1) && selectWheel(1)"
    (keydown.enter)="!isInJobItems(1) && selectWheel(1)"
    [matTooltip]="getPositionName(axleId + 1)"
    matTooltipPosition="above"></div>
</div>

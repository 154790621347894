<div class="animate__animated animate__fadeIn">
  <section class="w-full bg-gradient-to-t from-green-500 to-green-900 text-white">
    <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
      <div class="text-center">
        <h1 class="text-3xl sm:text-5xl font-extrabold leading-tight md:text-6xl">Booking Request Submitted</h1>
      </div>
    </div>
  </section>
  <div class="page-wrapper">
    <div class="flex flex-col items-center justify-center p-10 animate__animated animate__fadeIn">
      <div class="text-center mt-4 space-y-4">
        <h1 class="text-4xl font-extrabold mb-2 text-gray-600">Thank you for making your booking with Direct Tyre Management.</h1>
        <h2 class="text-3xl font-bold mb-2 text-gray-600">
          Your reference is
          <span class="font-extrabold text-blue-500 block">{{ 'SP' + spRef }}</span>
        </h2>
        <p class="text-2xl">We have sent you an email that you should read to check the information you have provided is correct.</p>
        <p class="text-lg">Thank you for using Direct Tyre Management.</p>
        <button class="mt-4" mat-raised-button color="primary" routerLink="/ext/booking">Request Another Booking</button>
      </div>
    </div>
  </div>
</div>

<div class="mt-9 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
  <div class="mx-auto max-w-xl text-center">
    <h2 class="text-3xl font-extrabold sm:text-5xl mb-4">We've Got Your Location!</h2>

    <div class="success-checkmark">
      <div class="check-icon">
        <span class="icon-line line-tip"></span>
        <span class="icon-line line-long"></span>
        <div class="icon-circle"></div>
        <div class="icon-fix"></div>
      </div>
    </div>
    <p class="mx-auto mt-2 text-gray-700">Thank you for providing your location.</p>
  </div>
</div>
